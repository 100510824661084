<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Compform from "./components/Compform";
import { fetchCompanyListApi } from "@/api/common";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "./api";

export default {

   import: {fetchCompanyListApi},
    page: {
        title: "l'Etreprises",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { Layout, PageHeader,Compform,
     Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      DatasetSearch,
      DatasetShow,},
    data() {
    return {
      data: [],
      selectedCompany: null,
      tableData: [],
      title: "Entreprises ",
      List_Company: [],
      rows:'',
      active:false,
      selectedRows:[],
      Company:undefined,
      refrechTable:undefined,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Entreprise Externe",
          to: { name: 'master_data.referentiels.extreme_business' }
        },
        {
          text: "Entreprises",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      cols: [
        {
          name: "Nom d'entreprise",
          field: "company_name",
          sort: ''
        },
        {
          name: "Télèphone",
          field: "company_phone",
          sort: ''
        },
        {
          name: "Adresse",
          field: "company_adr",
          sort: ''
        },
      ],
      selectedIndex:-1, 
      currentSelectedComp : null,
      selectedComp : null
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;

    // fetch divisions from api

    this.fetchCompanyList();
  },
  watch:{
    refrechTable:{
      handler(val){
        console.log(val);
        this.fetchCompanyList();
        this.refrechTable=false;
      }
    },
      selectedRows(){
        if(this.selectedRows.length){
          //console.log(this.selectedRows[0]);
         this.selectedComp = this.selectedRows[0];
        }
        else{
          this.selectedComp =[];
        }
      },
      Company:{
          handler(val){
            this.List_Company.push({
                Company_name:val.Company_name,
                Company_phone:val.Company_Phone,
                Company_adr:val.Company_adr,
                comments:val.comments,
            });
            
            console.log(this.List_Company);
          }
          
          
      }
  },
  methods:{
     selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
    fetchCompanyList(){
      fetchCompanyListApi()
      .then(res => (this.List_Company = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getCompany(){
      this.$http.get('/referentiel/company_visitor/list')
      .then(response => {
        console.log(response.data);
          this.data = response.data;
      })
      .catch(error => console.log(error))
    },
    refreshCompany(){
      this.getCompany();
    },
     deleteCompany(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer l'Entreprise :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/referentiel/company_visitor/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchCompanyList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
   editCompany(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("/referentiel/company_visitor/getSingleCompanyData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var company = res.data.original.data.company;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedComp = company;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
     fetchCopmanyDetails(uuid = null) {
      let loader = this.$loading.show();
      this.$http.post("/referentiel/company_visitor/getSingleCompanyData/" + uuid)
        .then((res) => {
          var status = res.data.original.status;
          var company = res.data.original.data.company;
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedComp = company;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadCompanyDataToFormComponent(selectedCompany){
      if(selectedCompany){
        this.fetchCompanyList(selectedCompany.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedComp.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedComp.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedComp.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedComp.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedComp.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(company){

      this.currentSelectedComp = company;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshCompanyTable = true;
      // jump to next tab
      this.formStep = 1;
    },

  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des entreprises</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                 <dataset v-slot="{ ds }" :ds-data="List_Company" :ds-sortby="['company_name','company_phone','company_adr']">
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                    <div class="col-md-6">
                      <dataset-search ds-search-placeholder="Search..." />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.company_name }} </td>
                                <td>{{ row.company_phone }}</td>
                                <td>{{ row.company_adr }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                      <dataset-show :ds-show-entries="5" />
                    <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                    <dataset-pager />
                  </div>
                </dataset>
                <!-- <table class="table table-centered table-nowrap"> -->
                 <!--  <v-table
                    class="table table-hover"
                    :data="List_Company"
                    selectionMode="single"
                    selectedClass="table-info"
                     @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Nom d'entreprise</th>
                      <th>Télèphone</th>
                      <th>Adresse</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>
                        {{ row.company_name }}
                      </td>
                      <td>{{ row.company_phone }}</td>
                      <td>{{ row.company_adr }}</td>
                    </v-tr>
                  </tbody>
                </v-table> -->
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <Compform :base="selectedRows"  :company="currentSelectedComp" @refrechTable="refrechTable=$event" :selectedComp="selectedComp" />
        </div>
      </div>
    </div>
    </Layout>
</template>